import { reactive, Ref, ref } from 'vue';
import { enums } from '@optimizely/optimizely-sdk';
import { injectStrict } from '@/shared/utils/injectStrict';
import { FeaturesKey } from '@/shared/constants/injectables';

export type FeatureType = Record<string, boolean | undefined>;

const initialFeatures: FeatureType = {};

interface UseFeaturesType {
  initializedOptimizely: Ref<boolean>;
  features: FeatureType;
  updateFeatures: () => void;
}

// TODO : maybe should be named useFeatureFlags
export const useFeatures = (): UseFeaturesType => {
  const featuresService = injectStrict(FeaturesKey);

  const { optimizelyInst, isEnabled, getFeatures } = featuresService;
  const initializedOptimizely = ref(false);

  const features = reactive<FeatureType>(initialFeatures);

  const updateLegacyFlags = () => {
    features.hideMissingFeatures = isEnabled('fe-hide-missing-features');
    features.showFeedback = isEnabled('fe-show-user-feedback');
    features.showOrderHistoryLink = isEnabled('fe-show-order-history-link');
    features.enableLanguageSwitcher = isEnabled('fe-enable-language-switcher');
    features.showGeneralOrderHistoryLink = isEnabled('fe-show-general-order-history-link');
    features.showNewOrderDetails = isEnabled('fe-show-new-order-details');
    features.showInvoiceEmailEdit = isEnabled('fe-show-invoice-email-edit');
    // New naming convention starts here
    features.editAddressEnabled = isEnabled('fe_kkdig_3674_edit_address_enabled');
    features.showAddContact = isEnabled('fe_kkdig_3673_add_contact_enabled');
    features.showEditContact = isEnabled('fe_kkdig_3675_edit_contact_enabled');
    features.orderHistorySearchEnabled = isEnabled('fe_kkdig_3679_order_history_search_enabled');
    features.deleteAddressEnabled = isEnabled('fe_kkdig_3795_delete_address_enabled');
    features.deleteContactEnabled = isEnabled('fe_kkdig_3793_delete_contact_enabled');
    features.productOverviewEnabled = isEnabled('fe_kkdig_3033_product_overview_enabled');
    features.changeMainContactEnabled = isEnabled('fe_kkdig_3797_change_main_contact_enabled');
    features.changeStandardAddressEnabled = isEnabled(
      'fe_kkdig_3798_change_standard_address_enabled'
    );
    features.removeLocationNameEnabled = isEnabled('fe_kkdig_3896_remove_location_name_enabled');
    features.checkoutEmployeePrefill = isEnabled('fe_kkdig_3988_checkout_employee_prefill');
    features.contactUniquenessCheck = isEnabled('be_kkdig_4107_contact_uniqueness_check_enabled');
    features.showRequiredInputFieldMarks = isEnabled(
      'fe_kkdig_3764_show_required_input_field_marks'
    );
    features.showCheckoutInvoiceEmailEmpty = isEnabled(
      'fe_kkdig_4502_checkout_invoice_email_empty'
    );
    features.showUpdatedProductDetailsTitle = isEnabled(
      'fe_kkdig_4407_show_updated_product_details_title'
    );
    features.showNewClickAndCollect = isEnabled('fe_kkdig_4461_new_click_and_collect_enabled');
    features.showCheckoutInvoiceEmailInvalid = isEnabled(
      'fe_kkdig_4502_checkout_invoice_email_invalid'
    );
    features.showVolumeDiscount = isEnabled('fe_4745_volume_discount_enabled');
    features.showOutputChannel = isEnabled('ep_4803_output_channel_enabled');
    features.newContactEditEndpoint = isEnabled('fe_5382_new_contact_edit_endpoint_enabled');
    features.resendRegistrationConfirmation = isEnabled(
      'fe_kkc_133_resend_registration_confirmation'
    );
    features.fe_kkc_137_distribution_channel_preview = isEnabled(
      'fe_kkc_137_distribution_channel_preview'
    );
    features.fe_kkc_139_country_change_in_address = isEnabled(
      'fe_kkc_139_country_change_in_address'
    );
    features.kkc_159_attlasian_feedback_widget = isEnabled('kkc_159_attlasian_feedback_widget');
    features.fe_kkc_17_email_changelog = isEnabled('fe_kkc_17_email_changelog');
    features.historyPriceDiscounts = isEnabled('kkc_246_orders_history_price_with_discount');
    features.fe_kkc_308_maintenance_mode = isEnabled('fe_kkc_308_maintenance_mode');
    features.fe_kkc_347_order_v3 = isEnabled('fe_kkc_347_order_v3');
  };

  const updateFeatures = () => {
    Object.keys(features).forEach((key) => delete features[key]);
    updateLegacyFlags();
    Object.assign(features, getFeatures());
  };

  if (optimizelyInst) {
    optimizelyInst.onReady().then(() => {
      updateFeatures();

      optimizelyInst.notificationCenter.addNotificationListener(
        enums.NOTIFICATION_TYPES.OPTIMIZELY_CONFIG_UPDATE,
        updateFeatures
      );

      initializedOptimizely.value = true;
    });
  } else {
    updateFeatures();
  }

  return { initializedOptimizely, features, updateFeatures };
};
